import React from "react";
import styled from "styled-components";

const Button = styled.a`
  font-size: 1rem;
  font-weight: 400;
  border: solid 1px black;
  padding: 0.7em 2em 0.7em 2em;
  border-radius: 15px;
  &:hover {
    cursor: pointer;
  }
  background: whitesmoke;
`;

const ButtonBasic = ({ text }) => {
  return (
    <Button href="#contact-me" type="button" className="mt-3 slide-down-hidden text-hover">
      {text}
    </Button>
  );
};

export default ButtonBasic;
