export const carouselImagesData = [
  {
    name: "",
    description: "newlyweds kissing in the rain on their wedding day",
    url: "/resources/images/carousel/landscape/newlyweds-kissing-in-the-rain-on-their-wedding-day.jpg",
  },
  {
    name: "",
    description: "newlyweds lauging and sharing moment on top of staircase",
    url: "/resources/images/carousel/landscape/newlyweds-laughing-on-staircase.jpg",
  },
  {
    name: "",
    description: "newlyweds",
    url: "/resources/images/carousel/landscape/newlyweds-under-umbrella-in-the-rain.jpg",
  },
  {
    name: "",
    description: "",
    url: "/resources/images/carousel/landscape/newlyweds-in-hotel-lobby.jpg",
  },
];

export const fancyImagesData = [
  {
    name: "Carousel_1.jpg",
    description: "",
    url: "/resources/images/carousel/landscape/newlyweds-kissing-in-the-rain-on-their-wedding-day.jpg",
  },
  {
    name: "Carousel_2.jpg",
    description: "",
    url: "/resources/images/carousel/landscape/newlyweds-laughing-on-staircase.jpg",
  },
  {
    name: "Carousel_3.jpg",
    description: "",
    url: "/resources/images/carousel/landscape/newlyweds-under-umbrella-in-the-rain.jpg",
  },
  {
    name: "Carousel_4.jpg",
    description: "",
    url: "/resources/images/carousel/landscape/newlyweds-in-hotel-lobby.jpg",
  },
  {
    name: "Carousel_4.jpg",
    description: "",
    url: "/resources/images/wedding/landscape/newlyweds-just-married.jpg",
  },
  {
    name: "Carousel_4.jpg",
    description: "",
    url: "/resources/images/wedding/landscape/newlyweds-hugging.jpg",
  },
];
export const reviewImageData = {
  name: "Carousel_1.jpg",
  description: "",
  url: "/resources/images/wedding/landscape/Review.jpg",
};
export const weddingMainImagesData = [
  {
    name: "Carousel_1.jpg",
    description: "",
    url: "/resources/images/wedding/landscape/newlyweds-kissing-in-the-rain.jpg",
  },
];
export const weddingImagesData = [
  {
    name: "Carousel_1.jpg",
    description: "",
    url: "/resources/images/carousel/landscape/newlyweds-laughing-on-staircase.jpg",
  },
  {
    name: "Carousel_2.jpg",
    description: "",
    url: "/resources/images/carousel/landscape/newlyweds-kissing-in-the-rain-on-their-wedding-day.jpg",
  },
  {
    name: "Carousel_3.jpg",
    description: "",
    url: "/resources/images/carousel/landscape/newlyweds-in-hotel-lobby.jpg",
  },
  {
    name: "Carousel_4.jpg",
    description: "",
    url: "/resources/images/wedding/landscape/newlyweds-hugging.jpg",
  },
  {
    name: "Carousel_4.jpg",
    description: "",
    url: "/resources/images/wedding/landscape/newlyweds-making-a-promise.jpg",
  },
  {
    name: "Carousel_4.jpg",
    description: "",
    url: "/resources/images/wedding/landscape/newlyweds-looking-lovingly-at-each-other.jpg",
  },
];
export const birthdayMainImagesData = [
  {
    name: "Carousel_1.jpg",
    description: "",
    url: "/resources/images/birthday/landscape/childrens-party-display-with-toys-and-candy.jpg",
  },
];
export const birthdayImagesData = [
  {
    name: "",
    description: "",
    url: "/resources/images/birthday/landscape/baby-chocolate-candles-with-animals.jpg",
  },
  {
    name: "",
    description: "",
    url: "/resources/images/birthday/landscape/balloon-with-lion-pendant.jpg",
  },
  {
    name: "",
    description: "",
    url: "/resources/images/birthday/landscape/child-laughing-and-playing-with-magic-wand.jpg",
  },
  {
    name: "",
    description: "",
    url: "/resources/images/birthday/landscape/childrens-party-display-with-toys-and-candy.jpg",
  },
  {
    name: "",
    description: "",
    url: "/resources/images/birthday/landscape/childrens-party-display-with-zoo-animals.jpg",
  },
  {
    name: "",
    description: "",
    url: "/resources/images/birthday/landscape/cute-child-looking-at-camera.jpg",
  },
  {
    name: "",
    description: "",
    url: "/resources/images/birthday/landscape/lion-and-hippo-with-chocolate.jpg",
  },
  // {
  //   name: "",
  //   description: "",
  //   url: "/resources/images/birthday/landscape/mother-dancing-with-son-laughing.jpg",
  // },
  // {
  //   name: "",
  //   description: "",
  //   url: "/resources/images/birthday/landscape/mother-dancing-with-son.jpg",
  // },
  {
    name: "",
    description: "",
    url: "/resources/images/birthday/landscape/orange-and-brown-chocolate-bears.jpg",
  },
  // {
  //   name: "",
  //   description: "",
  //   url: "/resources/images/birthday/landscape/son-hugging-mother.jpg",
  // },
  {
    name: "",
    description: "",
    url: "/resources/images/birthday/landscape/star-wars-balloon-display-at-birthday-party.jpg",
  },
  {
    name: "",
    description: "",
    url: "/resources/images/birthday/landscape/star-wars-balloon-display-kids-party.jpg",
  },
  {
    name: "",
    description: "",
    url: "/resources/images/birthday/landscape/son-laughing-and-hugging-mother.jpg",
  },
  // {
  //   name: "",
  //   description: "",
  //   url: "",
  // },
];
export const maternityMainImagesData = [
  {
    name: "Carousel_1.jpg",
    description: "",
    url: "/resources/images/maternity/landscape/mother-holding-baby-shoes.jpg",
  },
];
export const maternityImagesData = [
  {
    name: "Carousel_1.jpg",
    description: "",
    url: "/resources/images/maternity/landscape/couple-loving-with-buildings-in-the-background.jpg",
  },
  {
    name: "Carousel_2.jpg",
    description: "",
    url: "/resources/images/maternity/landscape/new-parents-embracing.jpg",
  },
  {
    name: "Carousel_3.jpg",
    description: "",
    url: "/resources/images/maternity/landscape/new-parents-from-distance.jpg",
  },
  {
    name: "Carousel_4.jpg",
    description: "",
    url: "/resources/images/maternity/landscape/new-parents-looking-at-each-other.jpg",
  },
  {
    name: "Carousel_4.jpg",
    description: "",
    url: "/resources/images/maternity/landscape/mother-holding-baby-shoes.jpg",
  },
];
