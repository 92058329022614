import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/Footer";
import TopPhoto from "./TopPhoto";
import Navbar from "../../components/navbar/Navbar";
import Pricing from "./Pricing";
import MaternityTopPhotos from "./MaternityTopPhotos";
import LoadingSpinner from "../../components/LoadingSpinner";
import { fetchPhotos } from "../../helper/fetch";

const Maternity = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [maternityMainPhotos, setMaternityMainPhotos] = useState([]);
  const [maternityPhotos, setMaternityPhotos] = useState([]);

  useEffect(() => {
    let fetchCount = 0; // Initialize a fetch counter
    const totalFetches = 2; // Total number of fetches expected

    const handleFetchComplete = () => {
      fetchCount++; // Increment the fetch counter
      if (fetchCount === totalFetches) {
        // If all fetches are complete, update the loading state
        setIsLoading(false);
      }
    };
    fetchPhotos("maternity-main")
      .then((data) => {
        setMaternityMainPhotos(data);
        handleFetchComplete();
      })
      .catch((error) => console.error("Error fetching Maternity Main photos"));
    fetchPhotos("maternity")
      .then((data) => {
        setMaternityPhotos(data);
        handleFetchComplete();
      })
      .catch((error) => console.error("Error fetching Maternity photos"));
  }, []);

  return (
    <>
      {/* {isLoading ? (
        <LoadingSpinner />
      ) : (
        <> */}
      <Navbar />
      <TopPhoto photos={maternityMainPhotos} />
      <MaternityTopPhotos
        images={maternityPhotos}
        text={"MATERNITY"}
        tagline={
          "Maternity photography sessions can be held in the comfort of your home or in a location of your choice, such as a quiet park. Choosing a welcoming environment allows parents to feel relaxed and enjoy each other's company. Each session lasts approximately 1 to 2 hours, with the ideal period for the photo session being around 30-33 weeks of pregnancy."
        }
      />
      {/* <Packages /> */}
      <Pricing />
      <Footer />
      {/* </>
      )} */}
    </>
  );
};

export default Maternity;
