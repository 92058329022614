import React from "react";

const Image = ({ src, sendModalData }) => {
  return (
    <img
      src={src}
      className="w-100 shadow-1-strong rounded mb-4"
      alt=""
      height={"100%"}
      onClick={(e) => sendModalData(e)}
      loading="lazy"
    />
  );
};

export default Image;
