import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import styled from "styled-components";
import { carouselImagesData } from "../../helper/data";

const CarouselImage = styled(Carousel.Item)``;
const ImageTag = styled.img`
  @media only screen and (max-width: 768px) {
    height: 900px;
    object-fit: cover;
    object-position: -160px 0px;
    width: auto !important;
  }

  @media only screen and (max-width: 992px) {
    object-fit: cover;
  }
  @media only screen and (max-width: 1300px) {
    width: auto !important;
    max-height: 900px;
    object-fit: cover;
  }
  @media only screen and (max-width: 1250px) {
    object-position: -100px 0px;
  }
  @media only screen and (max-width: 1100px) {
    object-position: -200px 0px;
  }
  @media only screen and (max-width: 1000px) {
    object-position: -300px 0px;
  }
  @media only screen and (max-width: 750px) {
    object-position: -350px 0px;
  }
  @media only screen and (max-width: 650px) {
    object-position: -400px 0px;
  }
  @media only screen and (max-width: 600px) {
    object-position: -450px 0px;
  }
  @media only screen and (max-width: 600px) {
    object-position: -450px 0px;
  }
  @media only screen and (max-width: 570px) {
    object-position: -480px 0px;
  }
  @media only screen and (max-width: 520px) {
    object-position: -520px 0px;
  }
  @media only screen and (max-width: 485px) {
    object-position: -550px 0px;
  }
`;

const CarouselBasic = ({ imagesLoaded, setImagesLoaded }) => {
  const [index, setIndex] = useState(0);

  let totalImagesLoaded = 0;

  const handleOneImageLoaded = () => {
    totalImagesLoaded++;
    if (totalImagesLoaded >= carouselImagesData.length) {
      setImagesLoaded(true);
    }
  };

  useEffect(() => {
    if (imagesLoaded === false) {
      carouselImagesData.forEach((photo) => {
        const img = new Image();
        img.onload = handleOneImageLoaded;
        img.src = photo.url;
      });
    }
  }, [imagesLoaded]);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (index === 3) {
        // Assuming you have 3 slides, change this condition based on the number of slides
        setIndex(0); // Reset to the first slide
      } else {
        setIndex(index + 1); // Move to the next slide
      }
    }, 5000); // Interval in milliseconds
    return () => clearInterval(interval);
  }, [index]);

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      className="carousel-fade"
      controls={false}
      indicators={false}>
      {carouselImagesData &&
        carouselImagesData.map((photo, index) => (
          <CarouselImage key={index} className="carousel-image ">
            <ImageTag
              onLoad={() => handleOneImageLoaded()}
              className="d-block w-100"
              src={`${photo.url}`}
              alt="First slide"
            />
          </CarouselImage>
        ))}
    </Carousel>
  );
};

export default CarouselBasic;
