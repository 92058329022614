import React, { useEffect, useState } from "react";
import Top from "./Top";
import Packages from "./Packages";
import Footer from "../../components/footer/Footer";
import TopPhoto from "./TopPhoto";
import Navbar from "../../components/navbar/Navbar";
import { fetchPhotos } from "../../helper/fetch";

const Wedding = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [weddingPhotos, setWeddingPhotos] = useState([]);
  const [weddingMainPhotos, setWeddingMainPhotos] = useState([]);
  useEffect(() => {
    // let fetchCount = 0; // Initialize a fetch counter
    // const totalFetches = 2; // Total number of fetches expected
    // const handleFetchComplete = () => {
    //   fetchCount++; // Increment the fetch counter
    //   if (fetchCount === totalFetches) {
    //     // If all fetches are complete, update the loading state
    //     setIsLoading(false);
    //   }
    // };
    // fetchPhotos("wedding")
    //   .then((data) => {
    //     setWeddingPhotos(data);
    //     handleFetchComplete();
    //   })
    //   .catch((error) => console.error("Error fetching Wedding photos"));
    // fetchPhotos("wedding-main")
    //   .then((data) => {
    //     setWeddingMainPhotos(data);
    //     handleFetchComplete();
    //   })
    //   .catch((error) => console.error("Error fetching Wedding Main photos"));
  }, []);
  return (
    <>
      {/* {isLoading ? (
        <LoadingSpinner />
      ) : (
        <> */}
      <Navbar />
      <TopPhoto />
      <Top
        images={weddingPhotos}
        text={"Wedding"}
        tagline={"Documenting your story in an authentic and moving way"}
      />
      <Packages />
      <div className="container-lg">
        <img
          src="resources/wedding/landscape/.jpg"
          className="w-100 shadow-1-strong rounded mb-4"
          alt=""
          height={"100%"}
        />
      </div>
      <Footer />
    </>
    //   )}
    // </>
  );
};

export default Wedding;
