import React from "react";
import CardPricing from "./CardPricing";
import styled from "styled-components";

const Heading = styled.h3`
  font-size: 2rem;
  font-weight: 300;
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Packages = () => {
  const features1 = [
    "Pre-wedding",
    "Up to 10 hours of coverage",
    "Complete high-resolution image delivery",
    "Travel within a 50-mile radius from SW8",
  ];
  const features2 = [
    "Pre-wedding",
    "Up to 8 hours of coverage",
    "Complete high-resolution image delivery",
    "Travel within a 50-mile radius from SW8",
  ];
  const features3 = [
    "Pre-wedding",
    "Up to 6 hours of coverage",
    "Complete high-resolution image delivery",
    "Travel within a 50-mile radius from SW8",
  ];

  return (
    <>
      <div className="container-lg mb-5">
        <Heading className="mb-4 text-center">INVESTMENT</Heading>
        <Container>
          <CardPricing packageName={"Wedding Package 1"} price={"£1100"} features={features1} />
          <div>
            <CardPricing packageName={"Wedding Package 2"} price={"£900"} features={features2} />
          </div>
          <CardPricing packageName={"Wedding Package 3"} price={"£700"} features={features3} />
        </Container>
      </div>
    </>
  );
};

export default Packages;
