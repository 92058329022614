import React, { useEffect } from "react";
import TextContent from "./TextContent";
import ImageSection from "./ImageSection";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import TextCenterAbout from "./TextCenterAbout";
import styled from "styled-components";
import ContactUs from "../../components/forms/ContactUs";

const Container = styled.div``;
const Row = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    // padding: 2em 0 0 0;
  }
`;

const About = () => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("slide-down-show");
          }
          // else {
          //   entry.target.classList.remove("slide-down-show");
          // }
        });
      },
      {
        threshold: 0.5151515151511,
      }
    );
    const hiddenElements = document.querySelectorAll(".slide-down-hidden");
    hiddenElements.forEach((element) => observer.observe(element));
  }, []);
  return (
    <Container>
      <div className="container-lg">
        <Row className="row">
          <div className="col">
            <TextContent />
          </div>
          <div className="col">
            <ImageSection />
          </div>
        </Row>
      </div>
      {/* <TextCenterAbout /> */}
      {/* <ContactUs /> */}
      {/* <Footer /> */}
    </Container>
  );
};

export default About;
