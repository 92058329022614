export const fetchWeddingPhotos = async (url) => {
  const response = await fetch("http://localhost:8080/api/category/wedding");
  if (!response.ok) {
    throw new Error("Failed to fetch Wedding photos");
  }
  const data = await response.json();
  return data;
};
export const fetchWeddingMainPhotos = async (url) => {
  const response = await fetch("http://localhost:8080/api/category/wedding-main");
  if (!response.ok) {
    throw new Error("Failed to fetch Wedding photos");
  }
  const data = await response.json();
  return data;
};
export const fetchBirthdayPhotos = async (url) => {
  const response = await fetch("http://localhost:8080/api/category/birthday");
  if (!response.ok) {
    throw new Error("Failed to fetch Birthday photos");
  }
  const data = await response.json();
  return data;
};
export const fetchBirthdayMainPhotos = async (url) => {
  const response = await fetch("http://localhost:8080/api/category/birthday-main");
  if (!response.ok) {
    throw new Error("Failed to fetch Birthday photos");
  }
  const data = await response.json();
  return data;
};
export const fetchMaternityPhotos = async (url) => {
  const response = await fetch("http://localhost:8080/api/category/maternity");
  if (!response.ok) {
    throw new Error("Failed to fetch Maternity photos");
  }
  const data = await response.json();
  return data;
};
export const fetchMaternityMainPhotos = async (url) => {
  const response = await fetch("http://localhost:8080/api/category/maternity-main");
  if (!response.ok) {
    throw new Error("Failed to fetch Maternity photos");
  }
  const data = await response.json();
  return data;
};
export const fetchWeddingHomePhotos = async (url) => {
  const response = await fetch("http://localhost:8080/api/category/wedding-home");
  if (!response.ok) {
    throw new Error("Failed to fetch Wedding Home photos");
  }
  const data = await response.json();
  return data;
};
export const fetchCarouselPhotos = async (url) => {
  const response = await fetch("http://localhost:8080/api/category/carousel");
  if (!response.ok) {
    throw new Error("Failed to fetch Carousel photos");
  }
  const data = await response.json();
  return data;
};
export const fetchReviewPhotos = async (url) => {
  const response = await fetch("http://localhost:8080/api/category/review");
  if (!response.ok) {
    throw new Error("Failed to fetch Carousel photos");
  }
  const data = await response.json();
  return data;
};
export const fetchPhotos = async (url) => {
  const response = await fetch("http://185.170.58.147:8080/api/category/" + url);
  if (!response.ok) {
    throw new Error("Failed to fetch endpoint with url: " + url);
  }
  const data = await response.json();
  return data;
};
