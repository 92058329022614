import React, { useEffect } from "react";
import styled from "styled-components";
import ButtonBasic from "../buttons/ButtonBasic";

const Container = styled.div`
  // height: 40%;
  // width: 40%;
  background: rgb(0, 0, 0, 0);
  border: none;
`;

const Title = styled.h5`
  font-size: 2rem;
  font-weight: 400;
`;
const Text = styled.p`
  font-weight: 300;
`;
const CardBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleTextButtonCentered = ({ title, text }) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("slide-down-show");
          }
          // else {
          //   entry.target.classList.remove("slide-down-show");
          // }
        });
      },
      {
        threshold: 0.5151515151511,
      }
    );

    const hiddenElements = document.querySelectorAll(".slide-down-hidden");
    hiddenElements.forEach((element) => observer.observe(element));
  }, []);
  return (
    <Container className="card text-white text-center">
      <CardBody className="container card-body d-flex flex-column justify-content-center align-items-center slide-down-hidden">
        <Title className="card-title pb-3">{title}</Title>
        <Text className="card-text">{text}</Text>
        <div>
          <ButtonBasic text={"Contact Me!"} />
        </div>
      </CardBody>
    </Container>
  );
};

export default TitleTextButtonCentered;
