import React from "react";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Instagram = styled(FaInstagram)`
  font-size: 1.5em;
  &:hover {
    cursor: pointer;
  }
`;

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="container d-none d-md-block my-2">
      <hr></hr>
      <div className="d-flex justify-content-between">
        <p>© 2024 Nádia Flores. All rights reserved.</p>
        <ul className="list-unstyled d-flex">
          <li className="ms-3">
            <Instagram
              onClick={() => {
                window.location.replace("https://www.instagram.com/nalisfloresphoto/?hl=pt-br");
              }}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
