import React, { useEffect, useState } from "react";
import styled from "styled-components";

const SpinnerWrapper = styled.div`
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease;
`;

const Spinner = styled.div`
  height: 60px;
  width: 60px;
`;

const LoadingSpinner = () => {
  return (
    <SpinnerWrapper>
      <Spinner className="spinner-border text-primary" role="status">
        <span className=""></span>
      </Spinner>
    </SpinnerWrapper>
  );
};

export default LoadingSpinner;
