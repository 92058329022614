import React from "react";
import ListItem from "./ListItem";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";

const LogoContainer = styled.div`
  // font-family: "Glass Antiqua", cursive;
  font-weight: 800;
  font-size: 1.5rem;
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  @media only screen and (max-width: 1200px) {
    // justify-content: start;
  }
`;
const NavbarContainer = styled.div`
  height: 14em;
  @media only screen and (max-width: 992px) {
    height: auto;
  }
`;

const LogoTop = styled.span`
  margin-bottom: -0.5rem;
`;
const NadiaSpan = styled.span`
  font-weight: 500;
`;
const NadiaP = styled.p`
  font-weight: 100;
  text-align: center;
`;
const Instagram = styled(FaInstagram)`
  font-size: 1.5em;
  &:hover {
    cursor: pointer;
  }
`;

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <nav
      className="navbar navbar-expand-lg fixed-top"
      style={{
        background: "rgba(0,0,0, .4)",
        position: "absolute",
        top: "0",
      }}>
      <div className="container-fluid" style={{ top: "0px" }}>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>

        <NavbarContainer
          className="collapse navbar-collapse justify-content-center flex-column text-white"
          id="navbarNav">
          <LogoContainer className="mx-5" onClick={() => navigate("/")}>
            <NadiaP className="text-white">
              <NadiaSpan>NÁDIA</NadiaSpan> FLORES PHOTOGRAPHY
            </NadiaP>
          </LogoContainer>
          <ul className="navbar-nav align-items-center pb-4">
            <ListItem text={"Home"} link={""} />
            <ListItem text={"About Me"} link={"about"} />
            <ListItem text={"Wedding"} link={"wedding"} />
            <ListItem text={"Maternity"} link={"maternity"} />
            <ListItem text={"Birthday Party"} link={"birthdayParty"} />
            {/* <ListItem text={"Portfolio"} link={"portfolio"} /> */}
            {/* <ListItem text={"Contact Me"} link={"contactMe"} /> */}
          </ul>
          {/* <Instagram
            onClick={() =>
              window.location.replace("https://www.instagram.com/nalisfloresphoto/?hl=pt-br")
            }
          /> */}
        </NavbarContainer>
      </div>
    </nav>
  );
};

export default Navbar;
