import React, { useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 60%;
  background: rgb(0, 0, 0, 0);
  border: none;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Title = styled.h5`
  font-size: 3rem;
  font-weight: 200;
`;
const Text = styled.p`
  font-size: 1.2rem;
  font-weight: 100;
`;

const TitleTextButtonLeft = ({ title, text, buttonText }) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("slide-down-show");
          }
        });
      },
      {
        threshold: 0.5151515151511,
      }
    );

    const hiddenElements = document.querySelectorAll(".slide-down-hidden");
    hiddenElements.forEach((element) => observer.observe(element));
  }, []);
  return (
    <Container className="card text-center">
      <div className="card-body d-flex flex-column justify-content-center align-items-center ">
        <Title className="card-title pb-3 slide-down-hidden">{title}</Title>
        <Text className="card-text slide-down-hidden">"{text}"</Text>
      </div>
    </Container>
  );
};

export default TitleTextButtonLeft;
