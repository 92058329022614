import React from "react";
import { FaInstagram } from "react-icons/fa";
import { IoIosPhonePortrait } from "react-icons/io";
import { CiMail } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const ImageContainer = styled.img`
  margin-top: 10rem;
  @media only screen and (max-width: 992px) {
    margin-top: 1em;
  }
`;

const UnorderedList = styled.ul`
  @media only screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const ImageSection = () => {
  const navigate = useNavigate();
  return (
    <>
      <ImageContainer
        src="resources/canva/nadia-blob-oval.png"
        className="w-100 shadow-1-strong rounded mb-4"
        alt="..."
        loading="lazy"
      />
      <UnorderedList className="list-unstyled">
        <li className="ms-3 d-flex align-items-center instagram cursor">
          <FaInstagram />
          <span
            className="mx-3 cursor"
            onClick={() =>
              window.location.replace("https://www.instagram.com/nalisfloresphoto/?hl=pt-br")
            }>
            Instagram
          </span>
        </li>
        <li className="ms-3 d-flex align-items-center my-2">
          <IoIosPhonePortrait />
          <span className="mx-3">+44 7771 774 201</span>
        </li>
        <li className="ms-3 d-flex align-items-center">
          <CiMail />
          <span className="mx-3">nadiafloresfotografia@gmail.com</span>
        </li>
      </UnorderedList>
    </>
  );
};

export default ImageSection;
