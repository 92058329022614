import React, { useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import styled from "styled-components";
import ContactUs from "../../components/forms/ContactUs";
import TextContent from "../about/TextContent";
import ImageSection from "../about/ImageSection";

const Container = styled.div`
  background: ${(props) => props.backgroundColor};
  //   height: 100vh;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    padding: 2em 0 0 0;
  }
`;

const AboutHome = ({ backgroundColor }) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("slide-down-show");
          }
          // else {
          //   entry.target.classList.remove("slide-down-show");
          // }
        });
      },
      {
        threshold: 0.5151515151511,
      }
    );
    const hiddenElements = document.querySelectorAll(".slide-down-hidden");
    hiddenElements.forEach((element) => observer.observe(element));
  }, []);
  return (
    <Container>
      <div className="container-lg">
        <Row className="row">
          <div className="col-lg-6">
            <TextContent />
          </div>
          <div className="col-lg-6">
            <ImageSection />
          </div>
        </Row>
      </div>
    </Container>
  );
};

export default AboutHome;
