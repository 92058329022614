import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TitleTextButtonLeft from "../cards/TitleTextButtonLeft";
import { fetchPhotos } from "../../helper/fetch";
import { reviewImageData } from "../../helper/data";

const Col = styled.div`
  // height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10em 0 10em 0;
`;
const Col2 = styled.div`
  // height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10em 10em 10em 0;
  width: 100%;
  @media only screen and (max-width: 1500px) {
    padding: 8em 2em 8em 0;
  }
  @media only screen and (max-width: 1300px) {
    display: none;
  }
`;

const TextLeftImageRight = () => {
  const [photos, setPhotos] = useState([]);
  useEffect(() => {
    //   fetchPhotos("review").then((data) => {
    //     console.log("review photos: ", data);
    //     setPhotos(data);
    //   });
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("slide-down-show");
          }
        });
      },
      {
        threshold: 0.5151515151511,
      }
    );

    const hiddenElements = document.querySelectorAll(".slide-down-hidden");
    hiddenElements.forEach((element) => observer.observe(element));
  }, []);
  return (
    <div className="">
      <div className="row">
        <Col className="col">
          <TitleTextButtonLeft
            title={"Claudia & James"}
            text={
              "We feel incredibly lucky to have had Nadia as our photographer during such a special moment in our lives, and we can't put into words how thankful we are for the wonderful experience she gave us. Right from the start, Nadia showed outstanding professionalism. Thank you, Nadia, for capturing our moments in such a special way!"
            }
            buttonText={"LEARN MORE"}
          />
        </Col>
        <Col2 className="col">
          <img
            src={reviewImageData.url}
            className="w-100 shadow-1-strong rounded mb-4"
            alt={reviewImageData.description}
          />
        </Col2>
        ;
      </div>
    </div>
  );
};

export default TextLeftImageRight;
