import React from "react";
import Footer from "../../components/footer/Footer";
import List from "./List";
import Navbar from "../../components/navbar/Navbar";
import styled from "styled-components";

const Space = styled.div`
  height: 16em;
`;

const Portfolio = () => {
  return (
    <>
      <Space />
      <Navbar />
      <List />
      <Footer />
    </>
  );
};

export default Portfolio;
