import React from "react";
import ImageTitle from "../../components/cards/ImageTitle";

const List = () => {
  return (
    <div className="container-xl pt-5">
      <div className="row">
        <div className="col-lg-4">
          <ImageTitle
            src={"resources/images/wedding/landscape/Review.jpg"}
            title={"Weddings"}
            link={"wedding"}
          />
        </div>
        <div className="col-lg-4">
          <ImageTitle
            src={"resources/images/maternity/landscape/mother-holding-baby-shoes.jpg"}
            title={"Maternity"}
            link={"maternity"}
          />
        </div>
        <div className="col-lg-4">
          <ImageTitle
            src={"resources/images/birthday/landscape/baby-chocolate-candles-with-animals.jpg"}
            title={"Birthday Party"}
            link={"birthdayParty"}
          />
        </div>
      </div>
    </div>
  );
};

export default List;
