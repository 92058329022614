import React from "react";
import styled from "styled-components";

const H2 = styled.h2`
  font-size: 1.2rem;
  font-weight: 400;
`;
const Text = styled.span`
  font-weight: 100;
`;
const Price = styled.p`
  font-weight: 500;
`;

const CardPricing = ({ packageName, price, features }) => {
  return (
    <div className="card " style={{ border: "none" }}>
      <div className="card-body d-flex flex-column">
        <H2 className="card-title">{packageName}</H2>
        <Price className="card-title">{price}</Price>
        {features.map((feature, index) => (
          <div key={index}>
            <Text className="card-text">{feature}</Text>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardPricing;
