import React, { useEffect } from "react";
import styled from "styled-components";
import TitleTextButtonCentered from "../../components/cards/TitleTextButtonCentered";

const Container = styled.div`
  // height: 40vh;
  background: #bdbbb6;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2em;
  padding-bottom: 2em;
`;

const TextCenterAbout = () => {
  return (
    <Container>
      <TitleTextButtonCentered
        title={"Changing the digital world"}
        text={
          "I believe it's paramount to create a committed and genuine community that has a positive influence on people’s lives beyond the online sphere. With more people online than ever before, the competition for attention is fierce, and you have to work hard to appeal to the masses. That's why it's my goal to help you understand the power of social media and create connections that go beyond the internet."
        }
      />
    </Container>
  );
};

export default TextCenterAbout;
