import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import About from "./pages/about/About";
import Home from "./pages/home/Home";
import Wedding from "./pages/wedding/Wedding";
import BirthdayParty from "./pages/birthdayParty/BirthdayParty";
import Portfolio from "./pages/portfolio/Portfolio";
import Maternity from "./pages/maternity/Maternity";
import AboutFull from "./pages/about/AboutFull";
import ContactMePage from "./pages/contactMe/ContactMePage";
// import dotenv from "dotenv";
// import path from "path-browserify";

// dotenv.config();

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route path={"/about"} element={<AboutFull />} />
        <Route path={"/wedding"} element={<Wedding />} />
        <Route path={"/birthdayParty"} element={<BirthdayParty />} />
        <Route path={"/portfolio"} element={<Portfolio />} />
        <Route path={"/maternity"} element={<Maternity />} />
        <Route path={"/contactMe"} element={<ContactMePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
