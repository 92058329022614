import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ImageModal from "../../components/gallery/ImageModal";
import { birthdayImagesData } from "../../helper/data";
import LoadingSpinner from "../../components/LoadingSpinner";

const Container = styled.div``;

const H2 = styled.h2`
  padding-top: 2rem;
  padding-bottom: 4rem;
  font-size: 2.5rem;
  font-weight: 400;
  @media only screen and (max-width: 768px) {
    padding-top: 3em;
  }
`;
const ResponsiveDiv = styled.div`
  padding-bottom: 1.5rem;
`;

const Row = styled.div``;

const BPPhotos = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [loadedImages, setLoadedImages] = useState([]);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const sendModalData = (e) => {
    setModalData(e.target.src);
    setShowModal(true);
  };
  const handleImageLoad = (index) => {
    setLoadedImages((prev) => [...prev, index]);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("slide-down-show");
          }
        });
      },
      {
        threshold: 0.5151515151511,
      }
    );
    const hiddenElements = document.querySelectorAll(".slide-down-hidden");
    hiddenElements.forEach((element) => observer.observe(element));
  }, []);

  let totalImagesLoaded = 0;

  const handleOneImageLoaded = () => {
    totalImagesLoaded++;
    if (totalImagesLoaded >= birthdayImagesData.length) {
      setImagesLoaded(true);
    }
  };

  useEffect(() => {
    if (imagesLoaded === false) {
      birthdayImagesData.forEach((photo) => {
        const img = new Image();
        img.onload = handleOneImageLoaded;
        img.src = photo.url;
      });
    }
  }, [imagesLoaded]);
  return (
    <Container>
      {showModal && <ImageModal modalData={modalData} onClose={() => setShowModal(false)} />}
      <div className="px-3 d-flex flex-column justify-content-center text-center pb-5">
        <H2 className="slide-down-hidden">Birthday Party</H2>
        <Row className="row">
          {birthdayImagesData.map((photo, index) => (
            <ResponsiveDiv key={index} className="col-lg-4 col-12  ">
              <img
                src={`${photo.url}`}
                className="w-100 shadow-1-strong rounded mb-4"
                alt=""
                height={"100%"}
                onClick={(e) => sendModalData(e)}
                loading="lazy"
                onLoad={() => handleImageLoad(index)}
                style={{
                  opacity: loadedImages.includes(index) ? 1 : 0,
                  transition: "opacity 0.5s",
                }}
              />
            </ResponsiveDiv>
          ))}
        </Row>
      </div>
    </Container>
  );
};

export default BPPhotos;
