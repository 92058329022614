import React from "react";
import styled from "styled-components";

const Container = styled.div`
  @media only screen and (max-width: 992px) {
    padding: 4em 0 4em 0;
  }
`;

const Title = styled.h3`
  @media only screen and (max-width: 992px) {
    // padding-top: 2em;
    text-align: center;
  }
`;
const Paragraph = styled.p`
  @media only screen and (max-width: 992px) {
    text-align: center;
  }
`;

const TextContent = () => {
  return (
    <Container className="d-flex flex-column justify-content-center">
      <Title className="pb-4">About</Title>
      <Paragraph className="">
        My name is Nádia Flores (1990) I am a Brazilian photographer from São Paulo, Brazil.
        Currently living in London. I deeply believe in the maxim that a picture is worth a thousand
        words. How can you best describe the warm, loving embrace of a couple in love? Or the purity
        and joy of a child's play? Photography has the power to capture these moments and turn them
        into special memories. It's as if each photo was a page of a unique story, written not with
        words, but with light, color and emotion. By choosing photography as my form of expression,
        I discover new ways of telling stories, of telling the world the deepest emotions and most
        precious moments in life.
      </Paragraph>
      <Paragraph>Come, let's create memories that stand the test of time.</Paragraph>
    </Container>
  );
};

export default TextContent;
