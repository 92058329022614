import React from "react";
import styled from "styled-components";
import { IoCheckmark } from "react-icons/io5";

const Icon = styled(IoCheckmark)`
  height: 1.5em;
  width: 1.5em;
  color: green;
`;
const H5 = styled.h5`
  font-size: 2rem;
  font-weight: 300;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  // background: whitesmoke;
  padding: 2em 0 2em 0;
`;
const Card = styled.div`
  // background: whitesmoke;
`;
const SubHeading = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
`;

const Pricing = () => {
  return (
    <Container>
      <Card className="card border-0">
        <div className="card-body">
          <H5 className="card-title">INVESTMENT</H5>
          <SubHeading className="card-subtitle">Birthday Party</SubHeading>
          <div className="d-flex flex-column py-4">
            <div>
              {/* <Icon /> */}
              <span className="card-text">Up to 3 hours of coverage</span>
            </div>
            <div>
              {/* <Icon /> */}
              <span className="card-text">Complete high-resolution image delivery</span>
            </div>
            <div>
              {/* <Icon /> */}
              <span className="card-text">USB Gift box</span>
            </div>
          </div>
          <h3 className="pb-5 ">£250</h3>
        </div>
      </Card>
    </Container>
  );
};

export default Pricing;
