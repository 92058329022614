import React from "react";
import { useNavigate } from "react-router-dom";

const ImageTitle = ({ src, title, link }) => {
  const navigate = useNavigate();
  return (
    <div className="card mb-5 border-0 " onClick={() => navigate("/" + link)}>
      <img src={src} className="card-img-top img-hov" alt="..." />
      <div className="card-body text-hover">
        <h5 className="card-title">{title}</h5>
      </div>
    </div>
  );
};

export default ImageTitle;
