import React from "react";
import Navbar from "../../components/navbar/Navbar";
import ContactUs from "../../components/forms/ContactUs";
import styled from "styled-components";
import Footer from "../../components/footer/Footer";

const Space = styled.div`
  height: 20em;
  @media only screen and (max-width: 992px) {
    height: 6em;
  }
`;

const ContactMePage = () => {
  return (
    <>
      <Navbar />
      <Space />
      <ContactUs />
      <Footer />
    </>
  );
};

export default ContactMePage;
