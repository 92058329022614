import { Carousel } from "react-bootstrap";
import React from "react";
import styled from "styled-components";
import { maternityMainImagesData } from "../../helper/data";
const CarouselImage = styled(Carousel.Item)``;

const ImageTag = styled.img`
  @media only screen and (max-width: 768px) {
    height: 900px;
    object-fit: cover;
    object-position: -160px 0px;
    width: auto !important;
  }

  @media only screen and (max-width: 992px) {
    // width: auto !important;
    // max-height: 650px;
    object-fit: cover;
  }
  @media only screen and (max-width: 1300px) {
    width: auto !important;
    max-height: 900px;
    object-fit: cover;
  }
  @media only screen and (max-width: 1250px) {
    object-position: -100px 0px;
  }
  @media only screen and (max-width: 1100px) {
    object-position: -200px 0px;
  }
  @media only screen and (max-width: 1000px) {
    object-position: -260px 0px;
  }
  @media only screen and (max-width: 750px) {
    object-position: -350px 0px;
  }
  @media only screen and (max-width: 650px) {
    object-position: -400px 0px;
  }
  @media only screen and (max-width: 600px) {
    object-position: -450px 0px;
  }
  @media only screen and (max-width: 600px) {
    object-position: -450px 0px;
  }
  @media only screen and (max-width: 570px) {
    object-position: -480px 0px;
  }
  @media only screen and (max-width: 520px) {
    object-position: -520px 0px;
  }
  @media only screen and (max-width: 485px) {
    object-position: -580px 0px;
  }
  // @media only screen and (max-width: 450px) {
  //   object-position: -600px 0px;
  // }
`;

const TopPhoto = ({ photos }) => {
  return (
    <Carousel
      className="carousel-fade"
      // style={{ height: "100vh" }}
      controls={false}
      indicators={false}>
      <CarouselImage className="carousel-image">
        {maternityMainImagesData.map((photo) => (
          <ImageTag className="d-block w-100" src={`${photo.url}`} alt="First slide" />
        ))}
      </CarouselImage>
    </Carousel>
  );
};

export default TopPhoto;
