import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Text = styled.a`
  font-weight: 200;
  margin: 0 1em 0 1em;
`;

const ListItem = ({ text, link }) => {
  const navigate = useNavigate();
  return (
    <li className="nav-item">
      <Text className="nav-link cursor text-white" onClick={() => navigate("/" + link)}>
        {text.toUpperCase()}
      </Text>
    </li>
  );
};

export default ListItem;
