import { Carousel } from "react-bootstrap";
import React, { useState } from "react";
import styled from "styled-components";
import { weddingMainImagesData } from "../../helper/data";
import LoadingSpinner from "../../components/LoadingSpinner";
const CarouselImage = styled(Carousel.Item)``;

const ImageTag = styled.img`
  @media only screen and (max-width: 768px) {
    height: 900px;
    object-fit: cover;
    object-position: -160px 0px;
    width: auto !important;
  }

  @media only screen and (max-width: 992px) {
    // width: auto !important;
    // max-height: 650px;
    object-fit: cover;
  }
  @media only screen and (max-width: 1300px) {
    width: auto !important;
    max-height: 900px;
    object-fit: cover;
  }
  @media only screen and (max-width: 1250px) {
    object-position: -100px 0px;
  }
  @media only screen and (max-width: 1100px) {
    object-position: -200px 0px;
  }
  @media only screen and (max-width: 1000px) {
    object-position: -300px 0px;
  }
  @media only screen and (max-width: 750px) {
    object-position: -350px 0px;
  }
  @media only screen and (max-width: 650px) {
    object-position: -400px 0px;
  }
  @media only screen and (max-width: 600px) {
    object-position: -450px 0px;
  }
  @media only screen and (max-width: 600px) {
    object-position: -450px 0px;
  }
  @media only screen and (max-width: 570px) {
    object-position: -480px 0px;
  }
  @media only screen and (max-width: 520px) {
    object-position: -520px 0px;
  }
  @media only screen and (max-width: 485px) {
    object-position: -550px 0px;
  }
`;

const TopPhoto = () => {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoaded = () => {
    setIsLoading(false);
  };
  return (
    <>
      {/* {isLoading ? (
        <LoadingSpinner />
      ) : ( */}
      <Carousel className="carousel-fade" controls={false} indicators={false}>
        <CarouselImage className="carousel-image">
          <ImageTag
            className="d-block w-100"
            src={`${weddingMainImagesData[0].url}`}
            alt="First slide"
            onLoad={() => handleLoaded()}
            loading="lazy"
          />
        </CarouselImage>
      </Carousel>
      {/* )} */}
    </>
  );
};

export default TopPhoto;
