import React, { useState } from "react";
import styled from "styled-components";

const ModalBackground = styled.div`
  background: black;
  height: 100vh;
  z-index: 1;
  opacity: 1;
`;
export const Modal = styled.div`
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100vh; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ModalContent = styled.div`
  height: 100vh;
  border-radius: 10px;
`;
export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;
const ImageTag = styled.img`
  height: 100vh;
`;

const ImageModal = (props) => {
  return (
    <Modal onClick={props.onClose}>
      <ModalContent className="carousel-image">
        <ImageTag
          loading="lazy"
          className="d-block w-100"
          src={props.modalData}
          alt="First slide"
        />
      </ModalContent>
    </Modal>
  );
};

export default ImageModal;
