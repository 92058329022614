import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/Footer";
import Pricing from "./Pricing";
import styled from "styled-components";
import Navbar from "../../components/navbar/Navbar";
import TopPhoto from "./TopPhoto";
import BPPhotos from "./BPPhotos";
import LoadingSpinner from "../../components/LoadingSpinner";
import { fetchPhotos } from "../../helper/fetch";

const ImageContainer = styled.div`
  padding: 4em 0 4em 0;
`;

const BirthdayParty = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [birthdayMainPhoto, setBirthdayMainPhoto] = useState([]);
  const [birthdayPhotos, setBirthdayPhotos] = useState([]);

  useEffect(() => {
    let fetchCount = 0; // Initialize a fetch counter
    const totalFetches = 2; // Total number of fetches expected

    const handleFetchComplete = () => {
      fetchCount++; // Increment the fetch counter
      if (fetchCount === totalFetches) {
        // If all fetches are complete, update the loading state
        setIsLoading(false);
      }
    };
    fetchPhotos("birthday-main")
      .then((data) => {
        setBirthdayMainPhoto(data);
        handleFetchComplete();
      })
      .catch((error) => console.error("Error fetching Birthday Main photos"));
    fetchPhotos("birthday")
      .then((data) => {
        setBirthdayPhotos(data);
        handleFetchComplete();
      })
      .catch((error) => console.error("Error fetching Birthday photos"));
  }, []);
  return (
    <>
      {/* {isLoading ? (
        <LoadingSpinner />
      ) : (
        <> */}
      <Navbar />
      <TopPhoto photos={birthdayMainPhoto} />
      <BPPhotos photos={birthdayPhotos} />
      <Pricing />
      <Footer />
      {/* </>
      )} */}
    </>
  );
};

export default BirthdayParty;
