import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import About from "./About";
import styled from "styled-components";

const Container = styled.div`
  //   background: rgba(193, 191, 188, 0.8);
`;

const Space = styled.div`
  height: 12em;
  @media only screen and (max-width: 900px) {
    height: 3em;
  }
`;

const AboutFull = () => {
  return (
    <Container>
      <Navbar />
      <Space />
      <About />
      <Footer />
    </Container>
  );
};

export default AboutFull;
