import React, { useRef, useState } from "react";
import styled from "styled-components";
import emailjs from "@emailjs/browser";
import ButtonSubmit from "../buttons/ButtonSubmit";
import ToastBasic from "../toast/ToastBasic";

const Input = styled.input`
  background: whitesmoke;
`;
const TextArea = styled.textarea`
  background: whitesmoke;
`;

const Container = styled.form`
  // height: 25em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 4em;
`;

const ContactUs = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [name, setName] = useState("");
  const [fromEmail, setFromEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    const email = {
      name,
      fromEmail,
      message,
    };
    console.log(email);
    fetch("http://185.170.58.147:8080/mail/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(email),
    });
  };
  return (
    <Container onSubmit={sendEmail} className="container my-5">
      <h4 id="contact-me" className="text-center pb-5">
        Contact Me!
      </h4>
      <div className="mb-3">
        <label htmlFor="exampleFormControlInput1" className="form-label">
          Your Name
        </label>
        <Input
          type="text"
          className="form-control"
          id="exampleFormControlInput1"
          name="user_name"
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="exampleFormControlInput1" className="form-label">
          Your Email
        </label>
        <Input
          type="email"
          className="form-control"
          id="exampleFormControlInput1"
          name="user_email"
          onChange={(e) => setFromEmail(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="exampleFormControlTextarea1" className="form-label">
          Your Message
        </label>
        <TextArea
          className="form-control"
          id="exampleFormControlTextarea1"
          rows={20}
          defaultValue={""}
          name="message"
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <div>
        <ButtonSubmit text={"Send Message"} />
      </div>
      {emailSent && (
        <span class="badge text-bg-success py-3 my-3">
          Your message has been sent successfully!
        </span>
      )}
    </Container>
  );
};

export default ContactUs;
