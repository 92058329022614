import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import TextLeftImageRight from "../../components/textImageCombo/TextLeftImageRight";
import Fancy from "../../components/gallery/Fancy";
import Footer from "../../components/footer/Footer";
import LoadingSpinner from "../../components/LoadingSpinner";
import CarouselBasic from "../../components/carousel/CarouselBasic";
import Navbar from "../../components/navbar/Navbar";
import AboutHome from "./AboutHome";

const Home = () => {
  const [carouselImagesLoaded, setCarouselImagesLoaded] = useState(false); // State to track if images are loaded
  const [fancyImagesLoaded, setFancyImagesLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (carouselImagesLoaded && fancyImagesLoaded) {
      setIsLoading(false); // Set loading to false when both children have finished loading
    }
  }, [carouselImagesLoaded, fancyImagesLoaded]);

  return (
    <>
      {/* {isLoading ? (
        <LoadingSpinner />
      ) : (
        <> */}
      <Navbar />

      <CarouselBasic
        setImagesLoaded={setCarouselImagesLoaded}
        imagesLoaded={carouselImagesLoaded}
      />
      <TextLeftImageRight />
      <Fancy setImagesLoaded={setFancyImagesLoaded} imagesLoaded={fancyImagesLoaded} />
      <AboutHome />
      <Footer />
    </>
    //   )}
    // </>
  );
};

export default Home;
