import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Image from "../../components/textImageCombo/Image";
import ImageModal from "../../components/gallery/ImageModal";
import { weddingImagesData } from "../../helper/data";

const Container = styled.div``;

const H4 = styled.h4`
  font-size: 1rem;
  font-weight: 200;
  padding: 1rem 12rem 3rem 12rem;
  @media only screen and (max-width: 768px) {
    padding: 1rem 0 3rem 0;
  }
`;
const H2 = styled.h2`
  padding-top: 2rem;
  font-size: 2.5rem;
  font-weight: 400;
  @media only screen and (max-width: 768px) {
    padding-top: 1em;
  }
`;
const ResponsiveDiv = styled.div`
  padding-bottom: 1rem;
`;

const Row = styled.div``;

const Top = ({ text, tagline }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  const sendModalData = (e) => {
    if (!isMobile) {
      setModalData(e.target.src);
      setShowModal(true);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("slide-down-show");
          }
        });
      },
      {
        threshold: 0.5151515151511,
      }
    );
    const hiddenElements = document.querySelectorAll(".slide-down-hidden");
    hiddenElements.forEach((element) => observer.observe(element));
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Container>
      {showModal && <ImageModal modalData={modalData} onClose={() => setShowModal(false)} />}
      <div className="px-3 d-flex flex-column justify-content-center text-center pb-5">
        <H2 className="slide-down-hidden">{text} </H2>
        <H4 className="slide-down-hidden">{tagline}</H4>
        <Row className="row">
          {weddingImagesData.map((photo, index) => (
            <ResponsiveDiv key={index} className="col-lg-4 col-12 ">
              <Image src={`${photo.url}`} sendModalData={sendModalData} />
            </ResponsiveDiv>
          ))}
        </Row>
      </div>
    </Container>
  );
};

export default Top;
