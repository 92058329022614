import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ImageModal from "./ImageModal";
import { fancyImagesData } from "../../helper/data";
import LoadingSpinner from "../LoadingSpinner";

const Container = styled.div`
  background: whitesmoke;
  padding: 10em 0 10em 0;
  @media only screen and (max-width: 1400px) {
    padding: 1em 0 1em 0;
  }
`;

const Gallery = ({ imagesLoaded, setImagesLoaded }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  // const [imagesLoaded, setImagesLoaded] = useState(false);

  let totalImagesLoaded = 0;

  const handleOneImageLoaded = () => {
    totalImagesLoaded++;
    if (totalImagesLoaded >= fancyImagesData.length) {
      setImagesLoaded(true);
    }
  };

  useEffect(() => {
    if (imagesLoaded === false) {
      fancyImagesData.forEach((photo) => {
        const img = new Image();
        img.onload = handleOneImageLoaded;
        img.src = photo.url;
      });
    }
  }, [imagesLoaded]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sendModalData = (e) => {
    if (!isMobile) {
      setModalData(e.target.src);
      setShowModal(true);
    }
  };
  return (
    <Container>
      {!imagesLoaded ? (
        <LoadingSpinner />
      ) : (
        <div className="px-3 pt-4">
          <div className="row">
            {showModal && <ImageModal modalData={modalData} onClose={() => setShowModal(false)} />}
            {fancyImagesData.map((photo, index) => {
              return (
                <div key={index} className="col-lg-4 col-12 mb-lg-0 ">
                  <img
                    onLoad={handleOneImageLoaded}
                    src={`${photo.url}`}
                    className="w-100 shadow-1-strong rounded mb-4"
                    alt="Boat on Calm Water"
                    onClick={(e) => sendModalData(e)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </Container>
  );
};

export default Gallery;
